import { useSubject } from 'ecce-preact';
import { useAccountController, useAccountDetails } from '../../../../../controllers';
import { DashboardTemplate } from '../../../templates/dashboard-template';
import { ActivateBillingView } from './activate-billing-view';
import { ActiveBillingView } from './active-billing-view';
import { InvoiceHistoryView } from './invoice-history-view';
import { PaymentFailedAlert } from './payment-failed-alert';


export const DashboardBillingContent = () => {
	return (
		<>
			<PaymentFailedAlert/>
			<ActivateBillingView/>
			<ActiveBillingView/>
			<InvoiceHistoryView invoices={ useAccountDetails().billing.invoices }/>
		</>
	);
};

export const DashboardBilling = () => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');

	return (
		<DashboardTemplate title="Billing">
			{ accountController.state.details && <DashboardBillingContent/>}
		</DashboardTemplate>
	);
};
